@import 'mixins';

$defaultFont: Roboto, sans-serif;
$menuFont: 'Roboto Condensed', sans-serif;

$borsBlack: #000000;

$menuColor: $borsBlack;
$buttonColor: $borsBlack;
$adBackground: #e5e5e5;

$exclusiveBottom: 1px white solid;

$fontColor: #ffffff;

a:hover, a:focus {
    color: $fontColor;
}

.HeaderLogo {
    background-image: url(Images/logo.svg);

    @include toSizeSM(){
        width: 200px;
    }
    @include fromSizeSM(){
        position: relative;
        width: 225px;
        top: 33px;
    }
    @include fromSizeLG() {
        width: 345px;
        height: 54px;
        position: relative;
        top: 18px;
    }
}

.container.header-inner, .custom-bg-active .headroom .container {
    background: $menuColor;
}

.header-main {
    @include fromSizeSM(){
    height: 100px;
    }
}

.HeaderSearch table {
    background: $borsBlack;
    border-radius: 0px;
    border-width: 0px;

    input {
        padding-left: 5px;
        padding-right: 5px;
        border-bottom: $exclusiveBottom;
        color: white;

        &::placeholder {
            color: white;
            text-transform: uppercase;
        }
    }

    .main-search-b {
        display: none;
    }
}

.HeaderSearch {
    top: 28px;
}
.cart-small-empty {
    @include fromSizeSM() {
        display: initial;
    }
}

.small-cart-onpage {
    @include fromSizeSM() {
        top: 31px;
    }
}

.icon-search {
    color: $fontColor;

    @include fromSizeSM(){
    color: $buttonColor;
    }
}

.popover-title {
    background-color: $buttonColor;
    border: none;
    color: $fontColor;
}

.cart-small-popover.popover.bottom>.arrow:after {
    border-bottom-color: $buttonColor;
}

.popover {
    padding: 0px;
}

.cart-small-popover {
    box-shadow: 0px 0px 15px rgba(200,200,200,0.5);
    .close{
        opacity: 1;
        text-shadow: 0px 0px 1px #fff;
    }
}

a.cart-small-trigger.btn.btn-default {
    background: transparent;
    color: white;
    border-radius: 0px;
    border: none;
    height: auto;

    @include fromSizeSM() {
        padding: 0px;
        text-align: left;
        min-width: 200px;
    }

    .cart-small-icon {
        @include fromSizeSM() {
            line-height: 18px;
            height: auto;

            i {
                font-size: 18px;
            }
        }

        @include fromSizeMD() {
            display: none;
            line-height: 33px;

            i {
                font-size: 18px;
            }
        }
    }
}
.cart-small {
    @include fromSizeSM {
        border-bottom: $exclusiveBottom;
    }
}
.cart-small-count, span.cart-small-items, .cart-small-empty {
    color: white;
}

.headroom {
    background-color: $menuColor;
}

.TopMenu a {
    @include fromSizeSM {
        color: #fff;
        text-transform: uppercase;
    }
}

.navbar-default .navbar-nav>li>a {
    font-size: 21px;
}

.navbar-default .navbar-nav > li > a {
    color: $fontColor;
    font-family: $menuFont;
    letter-spacing: initial;
}

.navbar-default .navbar-nav > li.selected > a {
    color: #fff;
}

.menu-container {
    background: $menuColor;
    border-bottom: 20px solid $buttonColor;

    @include toSizeSM() {
        padding-top: 43px;
        border-bottom: 10px solid $buttonColor;
    }
}

.section-menu {
    background: $menuColor;
}

.SubMenuBox,
.navbar-default .navbar-nav > li > a  {
    &:hover,
    &:focus,
    &:active {
        background: none;
        color: $fontColor;
        text-decoration: underline;
    }
}

.navbar-default .navbar-nav > .open > a {
    background-color: transparent;
    color: $fontColor;
    text-decoration: underline;
}

/* Fix li.selected: Fjernet pga endringer i hvordan menyen caches opp.
     Vi vet ikke alltid hva som er selected */
.SubMenuBox/*,
.navbar-default .navbar-nav > li.selected > a*/ {
    &:hover,
    &:focus,
    &:active {
        background: none;
        color: #fff;
    }
}

.InfoMenu {
    @include fromSizeSM(){
    color: $fontColor;
    }
}

.LogInButton {
    color: #fff;
    background: none;
    background-size: contain;
    background-repeat: no-repeat;
}

.LoginUserInfo {
    color: $fontColor;
}

.dropdown-menu a,
.dropdown-menu .title,
.dropdown-menu .menu-show-all {
    color: $menuColor;
}
.dropdown-menu .title,
.dropdown-menu .menu-show-all {
    font-family: $menuFont;
}

.mcm .dropdown.mcm-fw .dropdown-menu {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,2/10);
    border-top: none;
}

li.dropdown.selected {
    background: none;

}

.footer-container {
    background: $menuColor;
    color: white;
}

.copyright-container {
    background: $buttonColor;
    color: white;
    padding: 10px 0;
}

.copyright-container a {
    color: $fontColor;
}

a.cart-small-goto-checkout.btn.btn-primary {
    background: $buttonColor;
    border: none;
    border-radius: 0px;
    height: auto;
    padding-top: 5px;
    padding-bottom: 0px;
    padding-right: 0px;
    line-height: inherit;
}

.free-shipping-counter {
    background: $buttonColor;
}

.container-top-menu.icon-ellipsis-vert {
    color: $fontColor;
}

.bar1, .bar2, .bar3 {
    border: 2px solid #fff;
}

a.cart-small-trigger.btn.btn-default {
    @include toSizeSM(){
        background: $menuColor;
    }
}

.icon-basket {
    @include toSizeSM(){
        color: $fontColor;
    }
}

.ProductMenu a {
    color: $menuColor;
}

.ProductMenu .ProductMenuHeading {
    font-family: $menuFont;
}

.ProductMenu .Level1Selected {
    background: $menuColor;
}

.ProductMenu .Level1Selected a {
    color: white;
}

.ProductMenu .Level2Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 30px center;
}

.ProductMenu .Level3Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 45px center;
}

.section-menu-row {
    background: $menuColor;
    border-top: 1px solid $buttonColor;
    border-bottom: 1px solid $buttonColor;
}

.section-menu-item,
.section-header span {
    font-family: $menuFont;
    letter-spacing: initial;
}

.section-menu-item:hover {
    border-top: 2px solid $buttonColor;
}

.ProductInfo .heading-container h1,
.ProductInfo .heading-container h2 {
    font-family: $defaultFont;
}
.ProductInfo .heading-container h1 {
    font-size: 24px;
}
.ProductInfo .heading-container h2 {
    font-size: 20px;
}

.ProductInfo .section-header span::after {
    background: $buttonColor;
}

.BreadCrumb a:last-child {
    color: $buttonColor;
    font-weight: bold;
}

.btn-primary {
    background-color: $buttonColor;
    border: none;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background: lighten($buttonColor, 10%);
    }
}

.Layout3Element .favorite-button,
.Layout3Element .ad-buy-button {
    background: transparent;
    font-family: $menuFont;
    text-transform: uppercase;

    @include fromSizeSM() {
        background: $buttonColor;
        color: white;
    }
}

.Layout3Element .ad-buy-button {
    border-right-color: white;
}

.buy-product {
    background: $buttonColor;
    color: white;
    border-radius: 20px;
    font-family: $menuFont;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background: lighten($buttonColor, 10%);
    }
}

.TableList .BuyButtonSmall,
.TableList .VariantShowMore {
    border-radius: 0;
    background: $buttonColor;
    font-family: $menuFont;
    font-size: 18px;
    text-transform: uppercase;

    &:hover {
        background: darken($buttonColor, 10%);
    }
}

.TableList .BuyButtonSmallDisabled {
    border-radius: 0;
    font-family: $menuFont;
    font-size: 18px;
}

.PrdListCellsPagingTop a,
.PrdListCellsPagingBottom a,
.FieldPaging a {
    &:hover {
        background: $buttonColor;
    }
}

.PrdListCellsPagingTop a.SelectedPagingIndex,
.PrdListCellsPagingBottom a.SelectedPagingIndex,
.FieldPaging a.SelectedPagingIndex {
    background: $buttonColor;
}

.less-qty,
.more-qty {
    border-radius: 50%;
    border-color: $menuColor;
    background: transparent;
    color: $menuColor;
    margin-top: 6px;

    &:hover {
        background: #eee;
    }
}

.less-qty {
    margin-right: 5px;
}

.more-qty {
    margin-left: 5px;
}

.QuantityTextBox {
    background: transparent;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    border: 1px solid $menuColor;
    color: $menuColor;
}

/* the checked style using the :checked pseudo class */
.radio-inline input[type="radio"]:checked + span:before {
    background-color: $buttonColor;
}

.list-type-button {
    color: $buttonColor;
}

.ArticleWithBackground .heading-container * {
    font-family: $menuFont;
}
.ArticleWithBackground .heading-container h1 {
    font-size: 72px;
}
.ArticleWithBackground .heading-container h2 {
    font-size: 48px;
}

.free-shipping-counter {
    font-family: $defaultFont;
}